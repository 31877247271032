import React from "react";
import * as widont from 'widont'
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import colors from "lib/colors";
import Box from "./Box";
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";
import "swiper/css";
import "swiper/css/pagination";
import "./ItemSection.scss";
import { graphql } from "gatsby";

const TitleBox = ({ block }) => {
  const title = widont(block.title);
  const boxes = block.boxes;
  const removePadding = block.removeBoxSpacing;
  //Horizontal or Stacked
  const horizontalStacked = block.stackedOnHorizontal;
  let breakpointsStackedHorizontal;
  if (horizontalStacked === true) {
    breakpointsStackedHorizontal = {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
        direction: "vertical",
      },
      900: {
        slidesPerView: boxes.length,
        spaceBetween: 25,
        direction: "horizontal",
      },
    };
  } else if (horizontalStacked === false) {
    breakpointsStackedHorizontal = {
      0: {
        slidesPerView: 1.1,
        spaceBetween: 8,
        direction: "horizontal",
      },
      600: {
        slidesPerView: 1.5,
        spaceBetween: 25,
      },
      720: {
        slidesPerView: 2.2,
        spaceBetween: 25,
      },
      1000: {
        slidesPerView: boxes.length,
        spaceBetween: 25,
      },
    };
  }

  return (
    <Section className="itemSectionWrapper">
      {title && (
        <p className="s-12 l-8 text7" >
          {title}
        </p>
      )}
      <Swiper
        enabled={!horizontalStacked}
        breakpoints={breakpointsStackedHorizontal}
        pagination={!horizontalStacked}
        touchStartPreventDefault={false}
        preloadImages={false}
        updateOnImagesReady={false}
      >
        {boxes.map((box) => {
          const hasDarkBackground =
            box.backgroundColor && colors.isDarkColor(box.backgroundColor.hex);
          const backgroundColor =
            box.backgroundColor && box.backgroundColor.hex;

          return (
            <SwiperSlide
              style={{
                backgroundColor: backgroundColor ? backgroundColor : "#ffffff",
              }}
              key={box.id}
              className={hasDarkBackground ? "mono-container" : ""}
            >
              <Box
                box={box}
                styles={{
                  color: hasDarkBackground ? "#ffffff" : "#161616",
                  padding: removePadding ? 0 : "4rem",
                }}
                hasDarkBackground={hasDarkBackground}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Section>
  );
};

export default TitleBox;

TitleBox.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string,
    stackedOnHorizontal: PropTypes.bool.isRequired,
    removeBoxSpacing: PropTypes.bool,
    boxes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        backgroundColor: PropTypes.object.isRequired,
        content: PropTypes.object.isRequired,
        alignedLink: PropTypes.array,
      })
    ).isRequired,
  }),
};

export const query = graphql`
  fragment TitleBox on DatoCmsTitlebox {
    model {
      apiKey
    }
    id: originalId
    title
    stackedOnHorizontal
    removeBoxSpacing
    boxes {
      id: originalId
      ... on DatoCmsBox {
        backgroundColor {
          hex
        }
        alignedLink {
          ...Link
        }
        content {
          value
          blocks {
            __typename
            ... on DatoCmsImage {
              id: originalId
              image {
                gatsbyImageData(layout: FULL_WIDTH)
                focalPoint {
                  x
                  y
                }
                alt
                title
              }
            }
            ...Link
            ... on DatoCmsIcon {
              id: originalId
              iconType
              iconColor {
                hex
              }
            }
          }
        }
      }
    }
  }
`;

import React from "react";
import cn from 'classnames'
import { graphql } from "gatsby";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import PropTypes from "prop-types";
import LinkButton from "../LinkButton/LinkButton";
import * as TitleBoxStyling from "./TitleBox.module.scss";

const Box = ({ box, styles, hasDarkBackground}) => {
  const alignedLink = box.alignedLink[0];
  return (
    <div style={styles} className={cn(TitleBoxStyling.boxWrapper)}>
      <div>
        <StructuredTextRenderer
          data={box.content}
          iconClassName={cn(TitleBoxStyling.textIcon)}
          isParagraphClassName={cn('mb-0', { 'subtle': hasDarkBackground })}
          isHeadingClassName="mb-5"
          imageClassName="mb-5"
        />
      </div>
      {alignedLink && (
        <div>
          <LinkButton
            type={alignedLink.linkType}
            text={alignedLink.text}
            isExternal={alignedLink.pressForExternalLinking}
            internalLink={alignedLink.slug}
            externalLink={alignedLink.externalLink}
            buttonSize={alignedLink.buttonSize}
            trackingName={alignedLink.trackingName}
            textLinkClassName={"mb-0"}
          />
        </div>
      )}
    </div>
  );
};

export default Box;

Box.propTypes = {
  box: PropTypes.shape({
    id: PropTypes.string.isRequired,
    backgroundColor: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    alignedLink: PropTypes.array,
  }).isRequired,
};

export const query = graphql`
  fragment Box on DatoCmsBox {
    id: originalId
    backgroundColor {
      hex
    }
    alignedLink {
      ...Link
    }
    content {
      value
      blocks {
        __typename
        ... on DatoCmsImage {
          id: originalId
          image {
            gatsbyImageData
            alt
            title
          }
        }
        ...Link
        ... on DatoCmsIcon {
          id: originalId
          iconType
          iconColor {
            hex
          }
        }
      }
    }
  }
`;
